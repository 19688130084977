import gql from "graphql-tag"

export const FIND_BY_PROPERTY_TYPE = gql`
  query Finder($childrenSlug: [String]) {
    propertyTypes(where: { slug: "infiniteliving" }) {
      nodes {
        name
        children(where: { slug: $childrenSlug }) {
          nodes {
            name
            slug
            description
            acfPropertyType {
              sliderImageBannerWdesc {
                ... on PropertyType_Acfpropertytype_SliderImageBannerWdesc_Content {
                  title
                  description
                  image {
                    sourceUrl
                  }
                  link {
                    url
                  }
                }
              }
              sliderImageMobileWdesc {
                ... on PropertyType_Acfpropertytype_SliderImageMobileWdesc_Content {
                  title
                  description
                  image {
                    sourceUrl
                  }
                  link {
                    url
                  }
                }
              }
            }
            projects(first: 1000) {
              nodes {
                title
                slug
                featuredImage {
                  sourceUrl
                }
                priceProduct {
                  price
                }
                locations {
                  nodes {
                    name
                    slug
                  }
                }
                projectCustomfields {
                  ribbonDisc
                }
                propertyTypes(first: 200) {
                  nodes {
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SMART_MOVE_FIND_BY_LOCATION_AND_TYPE = gql`
  query Finder($locationId: [ID], $typeId: [ID], $parentId: Int) {
    projects(
      where: { locationIn: $locationId, propertyTypeIn: $typeId }
      first: 1000
    ) {
      nodes {
        title
        slug
        excerpt
        propertyTypes(where: { parent: $parentId }) {
          nodes {
            name
            slug
            propertyTypeId
          }
        }
        projectCustomfields {
          ribbonDisc
        }
        locations {
          nodes {
            slug
            locationId
            name
            description
            acfProjectLocation {
              banner {
                sourceUrl
              }
              mobileBanner {
                sourceUrl
              }
              sliderImageBannerWdesc {
                ... on Location_Acfprojectlocation_SliderImageBannerWdesc_Content {
                  title
                  description
                  image {
                    sourceUrl
                  }
                  link {
                    url
                  }
                }
              }
              sliderImageMobileWdesc {
                ... on Location_Acfprojectlocation_SliderImageMobileWdesc_Content {
                  title
                  description
                  image {
                    sourceUrl
                  }
                  link {
                    url
                  }
                }
              }
            }
          }
        }
        featuredImage {
          sourceUrl
          caption
          mediaDetails {
            sizes {
              name
              width
              height
              file
              sourceUrl
            }
          }
        }
      }
    }
  }
`
